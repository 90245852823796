import React from "react";
import Heading from "../../common/Heading";
import "./recent.css";
import { list } from "../../data/Data";
import { Link } from "react-router-dom";

const Recent = () => {
  // Slice the list to limit it to 6 items
  const limitedList = list.slice(0, 6);

  return (
    <>
      <section className="recent padding">
        <div className="container">
          <Heading
            title="Recent Events and Programs"
            subtitle="The recent programs and events conducted by YCNC in different places."
          />
          <div className="content grid3 mtop">
            {limitedList.map((val, index) => {
              const { cover, location, name } = val;
              return (
                <div className="box shadow" key={index}>
                  <div className="img">
                    <img src={cover} alt={name} />
                  </div>
                  <div className="text">
                    <div className="category flex">
                      <i className="fa fa-heart"></i>
                    </div>
                    <h4>{name}</h4>
                    <p>
                      <i className="fa fa-location-dot"></i> {location}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="show-more">
            <Link to="/event">
              <button className="btn-show-more">Show More</button>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Recent;
