import React from "react";
import { Link } from "react-router-dom";
import Heading from "../../common/Heading";
import { team } from "../../data/Data";
import "./team.css";

const Team = () => {
  // Select the first six team members
  const displayedTeam = team.slice(0, 6);

  return (
    <section className='team background'>
      <div className='container'>
        <Heading
          title='Our Team'
          subtitle='Meet the key members of our team.'
        />
        <div className='content mtop grid3'>
          {displayedTeam.map((member, index) => (
            <div className='box' key={index}>
              <button className='btn3'>{member.list}</button>
              <div className='details'>
                <div className='img'>
                  <img src={member.cover} alt={member.name} />
                  <i className='fa-solid fa-circle-check' style={{ zIndex: 1 }}></i>
                </div>
                <label>{member.organization}</label>
                <h4>{member.name}</h4>
                  {/* <ul>
                    {member.icon.map((icon, idx) => (
                      <li key={idx}>{icon}</li>
                    ))}
                  </ul> */}
                {/* <div className='button flex'>
                  <button>
                    <i className='fa fa-envelope'></i>
                    Message
                  </button>
                  <button className='btn4'>
                    <i className='fa fa-phone-alt'></i>
                  </button>
                </div> */}
              </div>
            </div>
          ))}
        </div>
        <div className='show-more'>
        <Link to='/TeamDetails'>
          <button className='btn-show-more'>Show More</button>
        </Link>
        </div>
      </div>
    </section>
  );
};

export default Team;
