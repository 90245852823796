import React, { useState } from "react";
import "./header.css";
import { nav } from "../../data/Data";
import { Link } from "react-router-dom";

const Header = () => {
  const [navList, setNavList] = useState(false);

  return (
    <>
      <header>
        <div className="container flex">
          <div className="logo">
            <img src="./images/YCNC_logo.jpg" alt="YCNC Logo" />
          </div>
          <div className="nav">
            <ul className={navList ? "small" : "flex"}>
              {nav.map((list, index) => (
                <li key={index}>
                  <Link to={list.path} onClick={() => setNavList(false)}>
                    {list.text}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="button flex">
            {/* <h4>
              <span>2</span> My List
            </h4> */}
            <Link to="/register" className="btn1">
              <button>
                <i className="fa fa-sign-out"></i>Register Now
              </button>
            </Link>
          </div>

          <div className="toggle">
            <button onClick={() => setNavList(!navList)}>
              {navList ? (
                <i className="fa fa-times"></i>
              ) : (
                <i className="fa fa-bars"></i>
              )}
            </button>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
