import React from "react";
import Back from "../common/Back";
import Heading from "../common/Heading";
import img from "../images/about.jpeg";
import "./about.css";

const About = () => {
  return (
    <>
      <section className="about">
        <Back name="About Us" title="About Us - Who We Are?" cover={img} />
        <div className="container flex mtop">
          <div className="left row">
            <Heading title="YCNC" subtitle="Youth Community for Nepalese Contractors" />
            <p>
              Youth Community for Nepalese Contractors (YCNC) is a group of like-minded young
              construction entrepreneurs coming together on one platform. We at YCNC believe that team
              effort fosters leadership and leadership for us is to think beyond self. We believe this character
              will lead to prosperity of the construction sector which in turn will lead to our collective growth.
            </p>
            <p>
              Hence we see an immense opportunity that can be created through this synergy in the field of
              construction.
            </p>
          </div>
          <div className="right row">
            <img src="./right.jpg" alt="About Us" />
          </div>
        </div>

        {/* Overview, Mission, and Objectives Section */}
        <div className="info-section">
          <div className="info-box">
            <h3>Overview</h3>
            <p>
              The association is a not-for-profit social organization. YCNC focuses on the young entrepreneurs
              and professionals in the industry working for member companies operating in Nepal and abroad.
              YCNC members will belong in their individual capacity. It will be open to all disciplines. YCNC was
              formed with a view to enhance the competitive strength and skills for Nepalese Contractors to be
              able to compete at the international level.
            </p>
            <p>
              YCNC envisages building on the network of peers thereby enhancing workplace effectiveness. It
              focuses on continuing education and training programs on pertaining issues and management
              principles to promote growth and competitiveness of our members.
            </p>
          </div>

          <div className="info-box">
            <h3>Mission</h3>
            <ul>
              <li>
                Promote and foster harmonious relationships among stakeholders based on mutual trust,
                understanding, and ethical values.
              </li>
              <li>
                Promote socially responsible, environmentally friendly, and safer construction practices.
              </li>
              <li>Embrace the latest technologies in construction and innovate.</li>
              <li>
                Provide effective platforms for information sharing, learning, and collaboration with global
                players.
              </li>
              <li>Implement Code of Conduct and Minimum Standards of Operation.</li>
              <li>Play a constructive role in the Construction Industry.</li>
            </ul>
          </div>

          <div className="info-box">
            <h3>Objectives</h3>
            <ul>
              <li>To be a not-for-profit social organization.</li>
              <li>
                To enhance the competitive strengths and skills of Nepalese Contractors to be able to compete
                with international contractors.
              </li>
              <li>To play a constructive role for the technology transfer to local industries.</li>
              <li>To act as a medium for discussion and sharing of experience.</li>
              <li>
                To organize seminars, workshops, and exhibitions of international standards.
              </li>
              <li>
                To advise national and international organizations related to the Construction Industry and
                assist stakeholders in convening their roles and responsibilities.
              </li>
              <li>To assist in providing training to members and stakeholders of the industry.</li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
