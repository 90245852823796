export const nav = [
  {
    text: "home",
    path: "/",
  },
  {
    text: "about",
    path: "/about",
  },
  {
    text: "News and Articles",
    path: "/newsandarticles",
  },
  {
    text: "Event",
    path: "/event",
  },
  {
    text: "Team",
    path: "/TeamDetails",
  },
  {
    text: "contact",
    path: "/contact",
  },  
]

export const featured = [
  {
    cover: "../images/hero/news1.JPG",
    name: "Catalyst Contractor",
    author: "Kantipur", // Optional for News & Articles
  },
  {
    cover: "../images/hero/news2.JPG",
    name: "CSR Programme",
    author: "Pachpokhari",
  },
  {
    cover: "../images/hero/news3.JPG",
    name: "CSR Programme",
    author: "Sitaram Kattel",
  },
]
export const list = [
  {
    id: 7,
    cover: "../images/list/NIS2024.jpg",
    name: "Nepal Infrastrucutre Summit, 2024",
    location: "Nepal",
  },
  {
    id: 5,
    cover: "../images/list/ADB headquarter visit at philippines.jpg",
    name: "ADB headquarter visit at Philippines",
    location: "Philippines",
  },
  {
    id: 2,
    cover: "../images/list/IFAWPCA convention 2023.jpeg",
    name: "IFAWPCA convention 2023",
    location: "Kathmandu",
  },
  {
    id: 3,
    cover: "../images/list/IFAWPCA headquarter visit at philippines.jpg",
    name: "IFAWPCA headquarter visit at Philippines",
    location: "Philippines",
  },
  {
    id: 1,
    cover: "../images/list/Earthquake Relief Program with Mahajodi.jpg",
    name: "Earthquake Relief Program with Mahajodi",
    location: "Nepal",
  },
  {
    id: 4,
    cover: "../images/list/Nepal Infrastructure Summit 2014.jpeg",
    name: "Nepal Infrastructure Summit 2014",
    location: "Kathmandu, Nepal",
  },
  {
    id: 6,
    cover: "../images/list/Nepal Infrastructure Summit 2022.jpeg",
    name: "Nepal Infrastructure Summit 2022",
    location: "Kathmandu, Nepal",
  },
]
export const awards = [
  {
    icon: <i class='fa-solid fa-trophy'></i>,
    num: "30 +	",
    name: "International Awards",
  },
  {
    icon: <i class='fa-solid fa-briefcase'></i>,
    num: "40 +",
    name: "Achievements",
  },
  {
    icon: <i class='fa-solid fa-lightbulb'></i>,
    num: "50 +",
    name: "Exclusive Members",
  },
  {
    icon: <i class='fa-solid fa-heart'></i>,
    num: "100 +",
    name: "Successful Events",
  },
]
export const location = [
  {
    id: 1,
    name: "New Orleans, Louisiana",
    Villas: "12 Villas",
    Apartments: "10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-1.png",
  },
  {
    id: 2,
    name: "Jerrsy, United State",
    Villas: "12 Villas",
    Apartments: "10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-2.png",
  },
  {
    id: 3,
    name: "Liverpool, London",
    Villas: "12 Villas",
    Apartments: " 10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-3.png",
  },
  {
    id: 4,
    name: "NewYork, United States",
    Villas: "12 Villas",
    Apartments: " 10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-4.png",
  },
  {
    id: 5,
    name: "Montreal, Canada",
    Villas: "12 Villas",
    Apartments: " 10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-5.png",
  },
  {
    id: 6,
    name: "California, USA",
    Villas: "12 Villas",
    Apartments: " 10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-6.png",
  },
]
export const team = [
  {
    list: "President",
    cover: "../images/customer/Mr. Basant Chand.jpeg",
    organization: "CAB construction Pvt. Ltd.",
    name: "Mr. Basant Chand",
  },
  {
    list: "Immediate Past President",
    cover: "../images/customer/Er. Sushil Kumar Shrestha.jpg",
    organization: "Kumar Shrestha Nirman Sewa Pvt. Ltd.",
    name: "Er. Sushil Kumar Shrestha",
  },
  {
    list: "First Vice President",
    cover: "../images/customer/Er. Raju Phuyal.jpg",
    organization: "Nagarjun Construction Company Pvt. Ltd.",
    name: "Er. Raju Phuyal",
  },
  {
    list: "Second Vice President",
    cover: "../images/customer/Er. Bheem Bahadur Pal.jpg",
    organization: "K.S Construction Pvt. Ltd",
    name: "Er. Bheem Bahadur Pal",
  },
  {
    list: "Third Vice President",
    cover: "../images/customer/Mr. Nirman Gauli.jpg",
    organization: "Ashish Nirman Sewa (P.) Ltd",
    name: "Mr. Nirman Gauli",
  },
  {
    list: "General Secretary",
    cover: "../images/customer/Er. Saurav Sharma.jpg",
    organization: "Sharma & Company Pvt. Ltd.",
    name: "Er. Saurav Sharma",
  },
  {
    list: "Treasurer",
    cover: "../images/customer/Er. Ajan Dahal.jpg",
    organization: "Rautaha Construction Pvt. Ltd.",
    name: "Er. Ajan Dahal",
    icon: [
      <i class='fa-brands fa-facebook-f'></i>,
      <i class='fa-brands fa-linkedin'></i>,
      <i class='fa-brands fa-twitter'></i>,
      <i class='fa-brands fa-instagram'></i>,
    ],
  },
  {
    list: "Executive Member",
    cover: "../images/customer/Mr. Ram Ji Pant.jpg",
    organization: "Bandan Bhagawati Nirman Sewa Pvt. Ltd.",
    name: "Mr. Ram Ji Panta",
    icon: [
      <i class='fa-brands fa-facebook-f'></i>,
      <i class='fa-brands fa-linkedin'></i>,
      <i class='fa-brands fa-twitter'></i>,
      <i class='fa-brands fa-instagram'></i>,
    ],
  },
  {
    list: "Executive Member",
    cover: "../images/customer/Mr.Karun Shrestha.jpg",
    organization: "HM Construction Pvt. Ltd.",
    name: "Mr. Karun Shrestha",
    icon: [
      <i class='fa-brands fa-facebook-f'></i>,
      <i class='fa-brands fa-linkedin'></i>,
      <i class='fa-brands fa-twitter'></i>,
      <i class='fa-brands fa-instagram'></i>,
    ],
  },
  {
    list: "Executive Member",
    cover: "../images/customer/Mr. Ujjwal Gautam.jpg",
    organization: "Lumbini Construction",
    name: "Mr. Ujjwal Gautam",
    icon: [
      <i class='fa-brands fa-facebook-f'></i>,
      <i class='fa-brands fa-linkedin'></i>,
      <i class='fa-brands fa-twitter'></i>,
      <i class='fa-brands fa-instagram'></i>,
    ],
  },
  {
    list: "Executive Member",
    cover: "../images/customer/Mr. Jebin Bikram Shahi.jpg",
    organization: "Amar Construction Pvt. Ltd.",
    name: "Mr. Jebin Shahi",
    icon: [
      <i class='fa-brands fa-facebook-f'></i>,
      <i class='fa-brands fa-linkedin'></i>,
      <i class='fa-brands fa-twitter'></i>,
      <i class='fa-brands fa-instagram'></i>,
    ],
  },
  {
    list: "Executive Member",
    cover: "../images/customer/arun_ghishing.jpeg",
    organization: "Lama Construction Pvt. Ltd.",
    name: "Mr. Arun Ghising",
    icon: [
      <i class='fa-brands fa-facebook-f'></i>,
      <i class='fa-brands fa-linkedin'></i>,
      <i class='fa-brands fa-twitter'></i>,
      <i class='fa-brands fa-instagram'></i>,
    ],
  },
  {
    list: "Executive Member",
    cover: "../images/customer/Mr. Paritosh Gupta.jpg", // Replace with actual path
    organization: "Pioneer Nepal",
    name: "Mr. Paritosh Gupta",
    icon: [
      <i class='fa-brands fa-facebook-f'></i>,
      <i class='fa-brands fa-linkedin'></i>,
      <i class='fa-brands fa-twitter'></i>,
      <i class='fa-brands fa-instagram'></i>,
    ],
  },
  {
    list: "Executive Member",
    cover: "../images/customer/Mr. Rajendra Mahatara.JPG", // Replace with actual path
    organization: "Auto-Electro-Mech Pvt. Ltd.",
    name: "Mr. Rajendra Mahatara",
    icon: [
      <i class='fa-brands fa-facebook-f'></i>,
      <i class='fa-brands fa-linkedin'></i>,
      <i class='fa-brands fa-twitter'></i>,
      <i class='fa-brands fa-instagram'></i>,
    ],
  },
  {
    list: "Executive Member",
    cover: "../images/customer/Mr. Shiddhanta Mani Rajbhandari.JPG", // Replace with actual path
    organization: "Nepal Construction & Engineering Corporation",
    name: "Mr. Shiddhanta Mani Rajbhandari",
    icon: [
      <i class='fa-brands fa-facebook-f'></i>,
      <i class='fa-brands fa-linkedin'></i>,
      <i class='fa-brands fa-twitter'></i>,
      <i class='fa-brands fa-instagram'></i>,
    ],
  },
];

export const generalMembers = [
  {
    list: "Founding President",
    cover: "../images/customer/nicholas.jpg",
    organization: "Kalika Construction Pvt. Ltd.",
    name: "Mr. Nicholas Pandey",
    icon: [
      <i class='fa-brands fa-facebook-f'></i>,
      <i class='fa-brands fa-linkedin'></i>,
      <i class='fa-brands fa-twitter'></i>,
      <i class='fa-brands fa-instagram'></i>,
    ],
  },
  {
    list: "Past President",
    cover: "../images/customer/Er. Birendra Raj Pandey.jpg",
    organization: "Nepal Adarsha Nirman Co. Pvt. Ltd.",
    name: "Er. Birendra Raj Pandey",
    icon: [
      <i class='fa-brands fa-facebook-f'></i>,
      <i class='fa-brands fa-linkedin'></i>,
      <i class='fa-brands fa-twitter'></i>,
      <i class='fa-brands fa-instagram'></i>,
    ],
  },
  {
    list: "Past President",
    cover: "../images/customer/Mr. Anand Subedi.jpg",
    organization: "Tundi Construction Pvt. Ltd.",
    name: "Mr. Anand Prasad Subedi",
    icon: [
      <i class='fa-brands fa-facebook-f'></i>,
      <i class='fa-brands fa-linkedin'></i>,
      <i class='fa-brands fa-twitter'></i>,
      <i class='fa-brands fa-instagram'></i>,
    ],
  },
  {
    list: "Past President",
    cover: "../images/customer/Er. Bikash Malla.jpg",
    organization: "Prera Nirman Sewa Pvt. Ltd.",
    name: "Er. Bikash Malla",
    icon: [
      <i class='fa-brands fa-facebook-f'></i>,
      <i class='fa-brands fa-linkedin'></i>,
      <i class='fa-brands fa-twitter'></i>,
      <i class='fa-brands fa-instagram'></i>,
    ],
  },

  {
    list: "Advisor",
    cover: "../images/customer/Mr. Deepak Raj Joshi.jpg",
    organization: "Joshi Builders & Co. Pvt. Ltd.",
    name: "Mr. Deepak Raj Joshi",
    icon: [
      <i class='fa-brands fa-facebook-f'></i>,
      <i class='fa-brands fa-linkedin'></i>,
      <i class='fa-brands fa-twitter'></i>,
      <i class='fa-brands fa-instagram'></i>,
    ],
  },
  {
    name: "Mr. Keshab Thokar",
    organization: "Tamang Construction Pvt. Ltd.",
    list: "Member",
  },
  {
    name: "Mr. Pawan Mahato",
    organization: "Raman Construction Pvt. Ltd.",
    list: "Member",
  },
  {
    name: "Mr. Sulav Mainaly",
    organization: "Kankai International Builders Pvt. Ltd.",
    list: "Member",
  },
  {
    name: "Mr. Biraj Malla",
    organization: "Gorkha Construction",
    list: "Member",
  },
  {
    name: "Mr. Sumit Rauniyar",
    organization: "Pappu Construction Pvt. Ltd.",
    list: "Member",
  },
  {
    name: "Mr. Santosh Shah",
    organization: "Ashrya Nirman Sewa Pvt. Ltd.",
    list: "Member",
  },
  {
    name: "Mr. Bhawes Bohra",
    organization: "Bhumi Construction Pvt. Ltd.",
    list: "Member",
  },
  {
    name: "Mr. Kumar Gautam",
    organization: "Sagarmatha Drilling",
    list: "Member",
  },
  {
    name: "Mr. Keshar Budathoki",
    organization: "",
    list: "Member",
  },
  {
    name: "Mr. Santosh Basnet",
    organization: "Swami Narayan",
    list: "Member",
  },
  {
    name: "Mr. Gyanesh Maharjan",
    organization: "Nati Construction",
    list: "Member",
  },
  {
    name: "Mr. Sandip Khadka",
    organization: "Surya Constructions",
    list: "Member",
  },
  {
    name: "Er. Rejish Adhikari",
    organization: "Apex Construction Company Pvt. Ltd.",
    list: "Member",
  },
  {
    name: "Er. Henif Dahal",
    organization: "Rautaha Construction Pvt. Ltd.",
    list: "Member",
  },
  {
    name: "Mr. Bijay Shah",
    organization: "Om Sai Ram Construction Pvt. Ltd.",
    list: "Member",
  },
  {
    name: "Mr. Sandeep Khadka",
    organization: "Bhimeshwor Drilling Tatha Nirman Sewa Pvt. Ltd.",
    list: "Member",
  },
  {
    name: "Mr. Madhu Sudan Dotel",
    organization: "Power Quality Construction",
    list: "Member",
  },
  {
    name: "Mr. Suman Khadka",
    organization: "Sailung Construction and Deep Tubewell Company Pvt. Ltd.",
    list: "Member",
  },
  {
    name: "Mr. Kshitiz Dhoj Thapa",
    organization: "Habitat Construction Engineers Pvt. Ltd.",
    list: "Member",
  },
  {
    name: "Mr. Sagar Bhattarai",
    organization: "M. R. And Group Private Limited",
    list: "Member",
  },
  {
    name: "Mr. Deepak Raj Giri",
    organization: "Baniya Nirman Sewa",
    list: "Member",
  },
  {
    name: "Mr. Sugam Ghimire",
    organization: "Sunam Infrastructure Nirman Pvt. Ltd.",
    list: "Member",
  },
];

export const associateMembers = [

  {
    name: "Mr. Aabhushan Jyoti Kansakar",
    organization: "Bhajurathna Agency Pvt. Ltd.",
    designation: "Member",
  },
  {
    name: "Mr. Vishal Tater",
    organization: "South Asian Infratech",
    designation: "Member",
  },
  {
    name: "Mr. Saurav Tibrewal",
    organization: "MRD Luxmi",
    designation: "Member",
  },
  {
    name: "Mr. Shyam Lal Agrawal",
    organization: "Chandeshwory Hardware",
    designation: "Member",
  },
  {
    name: "Mr. Bhim Ban",
    organization: "Gateway Logistic",
    designation: "Member",
  },
  {
    name: "Mr. Vikash Kedia",
    organization: "Vijaya Cement",
    designation: "Member",
  },
  {
    name: "Mr. Sushil Shrestha",
    organization: "Mansiri Trading",
    designation: "Member",
  },
  {
    name: "Mr. Narayan Nakarmi",
    organization: "World Wide",
    designation: "Member",
  },
  {
    name: "Mr. Akhil Gupta",
    organization: "Force Construction & Associates Pvt. Ltd.",
    designation: "Member",
  },
  {
    name: "Mr. Udeep Shrestha",
    organization: "Panchakanya Steel Pvt. Ltd.",
    designation: "Member",
  },
  {
    name: "Mr. Tirtha Adhikari",
    organization: "Nepal Construction and Equipment",
    designation: "Member",
  },
  {
    name: "Mr. Naresh Maharjan",
    organization: "Infra Solution",
    designation: "Member",
  },
  {
    name: "Mr. Sudeep Sharma Chaudhary",
    organization: "Simran Trading Pvt. Ltd.",
    designation: "Member",
  },
  {
    name: "Mr. Yam Rijal",
    organization: "Cargo Nepal Pvt. Ltd.",
    designation: "Member",
  },
];


export const price = [
  {
    plan: "Basic",
    price: "29",
    ptext: "per user, per month",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "99.5% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "120GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "5GB Cloud Storage",
      },
      { change: "color", icon: <i class='fa-solid fa-x'></i>, text: "Personal Help Support" },
      { change: "color", icon: <i class='fa-solid fa-x'></i>, text: "Enterprise SLA" },
    ],
  },
  {
    best: "Best Value",
    plan: "Standard",
    price: "49",
    ptext: "per user, per month",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "99.5% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "150GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "10GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        change: "color",
        icon: <i class='fa-solid fa-x'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Platinum",
    price: "79",
    ptext: "2 user, per month",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
]
export const footer = [
  {
    title: "LAYOUTS",
    text: [{ list: "Home Page" }, { list: "About Page" }, { list: "Service Page" }, { list: "Property Page" }, { list: "Contact Page" }, { list: "Single Project" }],
  },
  {
    title: "ALL SECTIONS",
    text: [{ list: "Headers" }, { list: "Features" }, { list: "Attractive" }, { list: "Testimonials" }, { list: "Videos" }, { list: "Footers" }],
  },
  {
    title: "COMPANY",
    text: [{ list: "About" }, { list: "Project" }, { list: "Pricing" }, { list: "Affiliate" }, { list: "Login" }, { list: "Changelog" }],
  },
]
