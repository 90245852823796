import React from "react";
import img from "../images/top3.jpeg"; // Replace with a relevant background image for News & Articles
import Back from "../common/Back";
import "../home/featured/Featured.css"; // Reusing Featured styles
import FeaturedCard from "../home/featured/FeaturedCard";

const Services = () => {
  return (
    <>
      <section className="services mb">
        {/* Back Section */}
        <Back name="News & Articles" title="Latest News & Articles" cover={img} />

        {/* Existing Featured Section */}
        <div className="featured container">
          <FeaturedCard type="news" />
        </div>

        {/* Additional Information Section */}
        <div className="additional-content container grid2">
          {/* Grand Manthan Card */}
          <div className="card">
            <h3>Grand Manthan</h3>
            <p>
              YCNC has been hosting significant forums called “Grand Manthan” since its inception,
              focusing on various contemporary issues within the construction sector. We invite
              experts from diverse fields to share insights and participate in panel discussions on these
              topics.
            </p>
            <p>The list of the Grand Manthans held over the years are:</p>
            <ul>
              <li>Economic Prosperity of the Nation - Role of Construction Industry (2016)</li>
              <li>Opportunities and Challenges of Construction Industry in Federal Structure (2017)</li>
              <li>Risk Management in Construction Contracts (2022)</li>
            </ul>
          </div>

          {/* CSR Activities Card */}
          <div className="card">
            <h3>CSR Activities</h3>
            <p>
              As a non-profit organization, YCNC has consistently fulfilled its corporate social responsibility
              (CSR) commitments. We have conducted various donation programs as needed. Some of the
              activities undertaken over the years include:
            </p>
            <ul>
              <li>Earthquake Relief Program (2015)</li>
              <li>Donation to Dhurmus Suntali Foundation (2018)</li>
              <li>Donation Program to the Flood Victim of Sindhupalchok (2021)</li>
              <li>
                Contribution towards the purchase of Oxygen Concentrators amid COVID-19 pandemic to
                the Rotary Club of Dhangadi (2021)
              </li>
              <li>Regular Contribution made to Mai Tej Bahadur Chand Trust (2020, 2023)</li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
