import React from "react";
import Header from "../common/header/Header";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../home/Home";
import Footer from "../common/footer/Footer";
import About from "../about/About";
import Project from "../project/Project";
import Services from "../services/Services";
import Contact from "../contact/Contact";
import RegisterForm from "../../Registerform";
import TeamDetails from "../home/team/TeamDetails"; // Ensure this import is correct
import ScrollToTop from "../ScrollToTop";

const Pages = () => {
  return (
    <Router>
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/newsandarticles" element={<Services />} />
        <Route path="/event" element={<Project />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/register" element={<RegisterForm />} />
        <Route path="/TeamDetails" element={<TeamDetails />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default Pages;
