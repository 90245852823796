import React from "react";
import Back from "../common/Back";
import RecentCard from "../home/recent/RecentCard";
import "../home/recent/recent.css";
import img from "../images/cover.jpeg";

const Project = () => {
  return (
    <>
      <section className="Project-out mb">
        <Back name="Events" title="Event Grid - Our Events" cover={img} />
        <div className="container recent">
          <RecentCard />
        </div>
        <div className="container timeline">
          <h2 className="timeline-title">Flagship Events Timeline</h2>
          <div className="timeline-grid">
            <div className="timeline-card">
              <h3>2014</h3>
              <ul>
                <li>YCNC Inauguration</li>
                <li>1st Nepal Infrastructure Summit in collaboration with CNI</li>
              </ul>
            </div>
            <div className="timeline-card">
              <h3>2016</h3>
              <ul>
                <li>1st Building and Construction Expo</li>
              </ul>
            </div>
            <div className="timeline-card">
              <h3>2017</h3>
              <ul>
                <li>2nd Nepal Infrastructure Summit in collaboration with CNI</li>
              </ul>
            </div>
            <div className="timeline-card">
              <h3>2018</h3>
              <ul>
                <li>2nd Building and Construction Expo</li>
              </ul>
            </div>
            <div className="timeline-card">
              <h3>2019</h3>
              <ul>
                <li>3rd Nepal Infrastructure Summit in collaboration with CNI</li>
              </ul>
            </div>
            <div className="timeline-card">
              <h3>2022</h3>
              <ul>
                <li>ADB and IFAWPCA headquarters visit in Manila, Philippines</li>
                <li>3rd Nepal Infrastructure Summit in collaboration with CNI</li>
              </ul>
            </div>
            <div className="timeline-card">
              <h3>2023</h3>
              <ul>
                <li>46th IFAWPCA Convention in collaboration with FCAN</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Project;
