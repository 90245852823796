import React, { useRef } from "react";
import emailjs from "emailjs-com"; // Import emailjs
import img from "../images/about.jpeg"; // Update path as needed
// import { useMediaQuery } from "react-responsive"; // Import useMediaQuery
import Back from "../common/Back";
import "./contact.css";

const Contact = () => {
  const form = useRef(); // Ref to access form data

  const sendEmail = (e) => {
    e.preventDefault(); // Prevent page reload on form submission

    emailjs
      .sendForm(
        "service_8on9ohm", // Replace with your EmailJS service ID
        "template_x3ibj1a", // Replace with your EmailJS template ID
        form.current, // Form reference
        "q2qrRzkMVu91TXaa5" // Replace with your EmailJS public key
      )
      .then(
        (result) => {
          console.log("Email successfully sent!", result.text);
          alert("Your message has been sent successfully!");
        },
        (error) => {
          console.log("Failed to send email.", error.text);
          alert("Something went wrong. Please try again later.");
        }
      );

    e.target.reset(); // Reset the form after submission
  };

  // const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <>
      <section className="contact mb">
        <Back
          name="We are here for you"
          title="Contact Us"
          cover={img} 
        />
        <div className="container">
          <div className="contact-layout">
            <form ref={form} onSubmit={sendEmail} className="shadow">
              <h4>Fill Up The Form</h4> <br />
              <div>
                <input type="text" name="name" placeholder="Name" required />
                <input type="email" name="reply_to" placeholder="Email" required />
              </div>
              <input type="text" name="subject" placeholder="Subject" required />
              <textarea name="message" cols="30" rows="10" placeholder="Message" required></textarea>
              <button type="submit">Submit Request</button>
            </form>
            <div className="map-container">
              <h4>Find Us Here</h4>
              <iframe
                title="Google Map"
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3735.9092386159955!2d85.312287!3d27.67184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjfCsDQwJzE4LjYiTiA4NcKwMTgnNDQuMiJF!5e1!3m2!1sen!2snp!4v1734944510538!5m2!1sen!2snp"
                width="100%"
                height="400"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
