import React, { useState } from "react";
import emailjs from "emailjs-com";
import "./registerform.css";

const RegisterForm = () => {
  const [activeForm, setActiveForm] = useState("general");
  const [formData, setFormData] = useState({});

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      form_type: activeForm === "general" ? "General Membership" : "Associate Membership",
      company_name: formData.company_name || "N/A",
      corporate_address: formData.corporate_address || "N/A",
      fax_no: formData.fax_no || "N/A",
      tel_no: formData.tel_no || "N/A",
      year_of_registration: formData.year_of_registration || "N/A",
      email: formData.email || "N/A",
      rep_name: formData.rep_name || "N/A",
      rep_designation: formData.rep_designation || "N/A",
      rep_address: formData.rep_address || "N/A",
      rep_mobile_no: formData.rep_mobile_no || "N/A",
      rep_tel_no: formData.rep_tel_no || "N/A",
      rep_fax_no: formData.rep_fax_no || "N/A",
      rep_email: formData.rep_email || "N/A",
      citizenship_no: formData.citizenship_no || "N/A",
      required_documents: activeForm === "general"
        ? "Copy of Citizenship, Employing at least one Engineer, Tax Clearance, Turnover Details, Years of Company"
        : "Company Registration and Citizenship Copy",
      submission_date: new Date().toLocaleString(),
    };

    // Send email using EmailJS
    emailjs
      .send(
        "service_8on9ohm", // Replace with your EmailJS Service ID
        "template_nrx814h", // Replace with your EmailJS Template ID
        templateParams, // Using templateParams here
        "q2qrRzkMVu91TXaa5" // Replace with your EmailJS Public Key
      )
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        alert("Form submitted successfully!");
      })
      .catch((error) => {
        console.log("FAILED...", error);
        alert("Failed to submit the form. Please try again.");
      });
  };

  // General Membership Form
  const renderGeneralForm = () => (
    <form onSubmit={handleSubmit} className="form-content">
      <h3>General Membership Form</h3>
      <h4>Member</h4>
      <div className="form-group">
        <label>Company Name:</label>
        <input type="text" name="company_name" onChange={handleChange} required />
      </div>
      <div className="form-group">
        <label>Address (Corporate Office):</label>
        <input type="text" name="corporate_address" onChange={handleChange} required />
      </div>
      <div className="form-group">
        <label>Fax No:</label>
        <input type="text" name="fax_no" onChange={handleChange} />
      </div>
      <div className="form-group">
        <label>Tel No:</label>
        <input type="text" name="tel_no" onChange={handleChange} required />
      </div>
      <div className="form-group">
        <label>Year of Registration:</label>
        <input type="text" name="year_of_registration" onChange={handleChange} required />
      </div>
      <div className="form-group">
        <label>Email:</label>
        <input type="email" name="email" onChange={handleChange} required />
      </div>

      <h4>Authorized Representative</h4>
      <div className="form-group">
        <label>Name:</label>
        <input type="text" name="rep_name" onChange={handleChange} required />
      </div>
      <div className="form-group">
        <label>Designation:</label>
        <input type="text" name="rep_designation" onChange={handleChange} required />
      </div>
      <div className="form-group">
        <label>Address (Permanent):</label>
        <input type="text" name="rep_address" onChange={handleChange} required />
      </div>
      <div className="form-group">
        <label>Mobile No:</label>
        <input type="text" name="rep_mobile_no" onChange={handleChange} required />
      </div>
      <div className="form-group">
        <label>Email:</label>
        <input type="email" name="rep_email" onChange={handleChange} required />
      </div>
      <div className="form-group">
        <label>Tel No:</label>
        <input type="text" name="rep_tel_no" onChange={handleChange} />
      </div>
      <div className="form-group">
        <label>Fax No:</label>
        <input type="text" name="rep_fax_no" onChange={handleChange} />
      </div>
      <div className="form-group">
        <label>Citizenship No:</label>
        <input type="text" name="citizenship_no" onChange={handleChange} required />
      </div>

      <h4>Required Documents for Registration:</h4>
      <ul>
        <li>📄 Copy of Nepali Citizenship of the Member</li>
        <li>📄 Employing at least one Engineer</li>
        <li>📄 Company Registration and Updated Tax Clearance Certificate</li>
        <li>📄 Minimum Annual Turnover of 50 Million</li>
        <li>📄 No. of Years of the Company</li>
      </ul>

      <button type="submit" className="btn-submit">Submit</button>
    </form>
  );

  // Associate Membership Form
  const renderAssociateForm = () => (
    <form onSubmit={handleSubmit} className="form-content">
      <h3>Associate Membership Form</h3>
      <h4>Member</h4>
      <div className="form-group">
        <label>Company Name:</label>
        <input type="text" name="company_name" onChange={handleChange} required />
      </div>
      <div className="form-group">
        <label>Address (Corporate Office):</label>
        <input type="text" name="corporate_address" onChange={handleChange} required />
      </div>
      <div className="form-group">
        <label>Fax No:</label>
        <input type="text" name="fax_no" onChange={handleChange} />
      </div>
      <div className="form-group">
        <label>Tel No:</label>
        <input type="text" name="tel_no" onChange={handleChange} required />
      </div>
      <div className="form-group">
        <label>Year of Registration:</label>
        <input type="text" name="year_of_registration" onChange={handleChange} required />
      </div>
      <div className="form-group">
        <label>Email:</label>
        <input type="email" name="email" onChange={handleChange} required />
      </div>

      <h4>Authorized Representative</h4>
      <div className="form-group">
        <label>Name:</label>
        <input type="text" name="rep_name" onChange={handleChange} required />
      </div>
      <div className="form-group">
        <label>Designation:</label>
        <input type="text" name="rep_designation" onChange={handleChange} required />
      </div>
      <div className="form-group">
        <label>Address (Permanent):</label>
        <input type="text" name="rep_address" onChange={handleChange} required />
      </div>
      <div className="form-group">
        <label>Mobile No:</label>
        <input type="text" name="rep_mobile_no" onChange={handleChange} required />
      </div>
      <div className="form-group">
        <label>Email:</label>
        <input type="email" name="rep_email" onChange={handleChange} required />
      </div>
      <div className="form-group">
        <label>Citizenship No:</label>
        <input type="text" name="citizenship_no" onChange={handleChange} required />
      </div>

      <h4>Required Documents for Registration:</h4>
      <ul>
        <li>📄 Company Registration and Updated Tax Clearance Certificate</li>
        <li>📄 Copy of Nepali Citizenship of the Authorized Representative</li>
      </ul>

      <button type="submit" className="btn-submit">Submit</button>
    </form>
  );

  return (
    <div className="register-form">
      <h2>Choose Your Membership Form</h2>
      <div className="form-selection">
        <button onClick={() => setActiveForm("general")} className={activeForm === "general" ? "btn active" : "btn"}>
          General Membership
        </button>
        <button onClick={() => setActiveForm("associate")} className={activeForm === "associate" ? "btn active" : "btn"}>
          Associate Membership
        </button>
      </div>
      {activeForm === "general" && renderGeneralForm()}
      {activeForm === "associate" && renderAssociateForm()}
    </div>
  );
};

export default RegisterForm;
