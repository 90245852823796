import React from "react";
import Heading from "../../common/Heading";
import { team } from "../../data/Data";
import { generalMembers, associateMembers } from "../../data/Data"; // Import data
import "./team.css";

const TeamDetails = () => {
  return (
    <section className="team background">
      <div className="container">
        <Heading
          title="Our Board Members"
          subtitle="Meet all the professionals who drive our success."
        />
        <div className="content mtop grid3">
          {team.map((member, index) => (
            <div className="box" key={index}>
              <button className="btn3">{member.list}</button>
              <div className="details">
                <div className="img">
                  <img src={member.cover} alt={member.name} />
                  <i
                    className="fa-solid fa-circle-check"
                    style={{ zIndex: 1 }}
                  ></i>
                </div>
                <label>{member.organization}</label>
                <h4>{member.name}</h4>
              </div>
            </div>
          ))}
        </div>

        {/* General Members Section */}
        <div className="general-members">
          <Heading
            title="General Members"
            subtitle="Valued members contributing to our success."
          />
          <div className="content mtop grid3">
            {generalMembers.map((member, index) => (
              <div className="box" key={index}>
                <button className="btn3">{member.list}</button>
                <div className="details">
                  {/* Show image if list is NOT "Member" */}
                  {member.list !== "Member" && member.cover && (
                    <div className="img">
                      <img src={member.cover} alt={member.name} />
                      <i
                        className="fa-solid fa-circle-check"
                        style={{ zIndex: 1 }}
                      ></i>
                    </div>
                  )}
                  <label>{member.organization}</label>
                  <h4>{member.name}</h4>
                  <p>{member.designation}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Associate Members Section */}
        <div className="associate-members">
          <Heading
            title="Associate Members"
            subtitle="Supporting our team with their expertise."
          />
          <div className="content mtop grid3">
            {associateMembers.map((member, index) => (
              <div className="associate-box" key={index}>
                <div className="details">
                  <h4>{member.name}</h4>
                  <label>{member.organization}</label>
                  <p>{member.designation}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamDetails;
