import React from "react";
// import { footer } from "../../data/Data"; // Keeping the footer import commented as per the original code
import "./footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <section className="footerContact">
        <div className="container">
          <div className="send flex">
            <div className="text">
              <h1>Do You Have Questions ?</h1>
              <p>We'll help you to grow your career and growth.</p>
            </div>
            <Link to="/contact">
              <button className="btn5">Contact Us Today</button>
            </Link>
          </div>
        </div>
      </section>

      <footer>
        <div className="container">
          {/* Main logo and content box */}
          <div className="box">
            <div className="logo flex">
              {" "}
              {/* Added flex for alignment */}
              <img src="../images/YCNC_logo_Light.png" alt="YCNC Logo" />
              <div className="content">
                {" "}
                {/* Content wrapped for better structure */}
                <h2>Do You Need Help With Anything?</h2>
                <p>Register now and join the group of contractors now.</p>
                <div className="input flex">
                  {/* Uncomment this input if you want email subscription */}
                  {/* <input type="text" placeholder="Email Address" /> */}
                  <button>
                    <Link to="/register" className="btn1">
                      Register Now
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Static Contact Information Section */}
          <div className="box">
            <h3>Contact Information</h3>
            <ul>
              <li>
                <strong>Location:</strong> Prera Business Complex, 1st floor, Jawalakhel
              </li>
              {/* <li>
                <strong>Phone:</strong> 01-4421166
              </li> */}
              <li>
                <strong>Email:</strong>
                <a
                  href="mailto:infoycnc@gmail.com"
                  style={{ color: "inherit", textDecoration: "none", textTransform: "none" }}
                >infoycnc@gmail.com</a>
                
              </li>
            </ul>
          </div>

          {/* Useful Links Section */}
          <div className="box">
            <ul>
              <li>
                <Link to="../about">About Us</Link>
              </li>
              <li>
                <Link to="/TeamDetails">Our Team</Link>
              </li>
              <li>
                <Link to="/event">Events</Link>
              </li>
              <li>
                <Link to="/newsandarticles">News and Articles</Link>
              </li>
            </ul>
          </div>

          {/* Social Media Links */}
          <div className="box social-media">
            <h3>Follow Us</h3>
            <ul className="social-icons">
              <li>
                <a
                  href="https://www.facebook.com/ycncnepal"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/ycnc_official/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://maps.app.goo.gl/eZUmgHiWMRdLNvNb7"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fas fa-map-marker-alt"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>

      <div className="legal">
        <span>© 2024 YCNC. Designed By Chill Tech.</span>
      </div>
    </>
  );
};

export default Footer;
