import React from "react";
import { featured } from "../../data/Data"; // Using a single data array

const FeaturedCard = ({ type }) => {
  // Filter data if required based on type
  const filteredData = type === "news" 
    ? featured.slice(0, 3) // For News & Articles, show only the first 3
    : featured;

  return (
    <>
      <div className="content grid5 mtop">
        {filteredData.map((item, index) => (
          <div className="box" key={index}>
            <img src={item.cover} alt={item.name} />
            <h4>{item.name}</h4>
            <label>{type === "news" ? item.author || "Author Unknown" : item.total}</label>
          </div>
        ))}
      </div>
    </>
  );
};

export default FeaturedCard;
